import {makeAutoObservable} from "mobx";

class Overlay {
  isOpen = false;
  overHeader = true;
  onMouseOver = () => {};

  constructor() {
    makeAutoObservable(this);
    this.toggle = this.toggle.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
  }

  toggle() {
    return (this.isOpen = !this.isOpen);
  }
  open(overHeader) {
    if (overHeader !== undefined) {
      this.overHeader = overHeader;
    }
    return (this.isOpen = true);
  }
  close() {
    this.overHeader = false;
    return (this.isOpen = false);
  }
  setOnMouseOver(fn) {
    return (this.onMouseOver = fn);
  }
}

export const overlay = new Overlay();
